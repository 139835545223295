import React, { Suspense, useRef, useState } from 'react'
import { Canvas, useFrame } from '@react-three/fiber'

import { PerspectiveCamera, useGLTF } from '@react-three/drei'

export function MyFallbackComponent() {
  return (
    <div>
      <h2>Loading...</h2>
      <p>Please wait while we load the 3D model.</p>
    </div>
  )
}

export const Skatie = ({ nodes, materials, group }) => {
  const groupRef = useRef()
  const [isKicking, setIsKicking] = useState(false)

  useFrame(({ clock }) => {
    const elapsedTime = clock.getElapsedTime()

    if (isKicking) {
      if (groupRef.current.rotation.x > -1.5) {
        groupRef.current.rotation.x -= 0.1
      }
    }
    // else {
    //   groupRef.current.rotation.x = Math.sin(elapsedTime) * 0.2
    // }

    // groupRef.current.rotation.z = elapsedTime * 0.25
    // groupRef.current.rotation.y = elapsedTime * 0.25
  })

  const handleKick = () => {
    setIsKicking(true)

    setTimeout(() => {
      setIsKicking(false)
    }, 1000)
  }

  return (
    <group ref={group} dispose={null}>
      <group
        position={[0, 0, 0]}
        rotation={[Math.PI / 2, 0, Math.PI * 1.1]}
        scale={1.15}
        ref={groupRef}
      >
        <mesh
          geometry={nodes.Mesh012.geometry}
          material={materials.SkateBoardSub}
        />
        <mesh geometry={nodes.Mesh012_1.geometry} material={materials.METAL} />
        <mesh geometry={nodes.Mesh012_2.geometry} material={materials.GRIP} />
        <mesh
          geometry={nodes.BOTTOM_DESIGN.geometry}
          material={materials.Material}
          position={[0, 0.32, 0.41]}
        />
        <mesh
          geometry={nodes.Wheel01.geometry}
          material={materials.WHEELS}
          position={[0.3, -4.14, 7.54]}
        />
        <mesh
          geometry={nodes.Wheel02.geometry}
          material={materials.WHEELS}
          position={[0.3, -4.14, 7.54]}
        />
        <mesh
          geometry={nodes.Wheel03.geometry}
          material={materials.WHEELS}
          position={[1.36, -0.5, -3.93]}
        />
        <mesh
          geometry={nodes.Wheel04.geometry}
          material={materials.WHEELS}
          position={[0.3, -4.14, 7.54]}
        />
      </group>
    </group>
  )
}

const Three = () => {
  const group = useRef()
  // const { nodes, materials } = useGLTF('/skateboard2.gltf')
  return (
    <Suspense fallback={<MyFallbackComponent />}>
      <div className='100vw 100vh'>
        <div className='w-100'>
          <div style={{ height: '500px', backgroundColor: 'white' }}>
            <Canvas></Canvas>
          </div>
        </div>
      </div>
    </Suspense>
  )
}

export default Three
