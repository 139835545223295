import { IKImage, IKContext } from 'imagekitio-react'

const ImageKitText = () => {
  return (
    <div className='vh-100 vw-100 d-flex align-items-center justify-content-center'>
      <iframe
        src='https://adz.wtf/robo?repeat=true'
        frameborder='0'
        className='frame-zoom'></iframe>
      {/* <IKContext
        publicKey='
public_jasKRAVM1iytUv69gQ9SRHvII/M=
'
        urlEndpoint='
https://ik.imagekit.io/0uzkjwu52a
'
        transformationPosition='
path
'>
        <IKImage
          path='
/minters/gify/1.gif
'
        />
      </IKContext> */}
    </div>
  )
}

export default ImageKitText
